import { defineStore } from 'pinia'
import { useToast } from '~/modules/ui/components/toast'

// The UserProfile type is inferred from the databse and defined in global.d.ts

type SupabaseClientType = ReturnType<typeof useSupabaseClient>

export const useUserProfileStore = defineStore('userProfile', () => {
  // Helpers
  const { start: startLoadingIndicator, finish: finishLoadingIndicator } = useLoadingIndicator({
    duration: 2000,
    throttle: 200,
  })

  // -- State  --
  const profile: Ref<UserProfile | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<string | null> = ref(null)
  const supabase = useSupabaseClient<SupabaseClientType>()
  const profileSubscription = ref<ReturnType<typeof supabase.channel> | null>(null)

  // -- Computed Properties (Getters)  --
  const isLoggedIn: ComputedRef<boolean> = computed(() => !!profile.value)
  const username: ComputedRef<string | null | undefined> = computed(() => profile.value?.username)
  const firstName: ComputedRef<string | null | undefined> = computed(() => profile.value?.first_name)
  const lastName: ComputedRef<string | null | undefined> = computed(() => profile.value?.last_name)
  const profileCompleted: ComputedRef<boolean> = computed(() => profile.value?.profile_complete || false)
  const onboardingCompleted: ComputedRef<boolean> = computed(() => profile.value?.onboarding_completed || false)

  // -- Actions  --

  // Fetch the user's profile based on their auth ID
  async function fetchProfile(): Promise<void> {
    loading.value = true
    error.value = null

    try {
      const { data: { user } } = await supabase.auth.getUser()

      if (!user) { throw new Error('User not found') }

      const { data, error: supabaseError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single()

      if (supabaseError) { throw supabaseError }

      profile.value = data as UserProfile
      subscribeToProfileChanges(user.id)
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error fetching user profile:', e)
    }
    finally {
      loading.value = false
    }
  }

  // Update the user's profile with new data
  async function updateProfile(updates: Partial<Omit<UserProfile, 'id'>>): Promise<void> {
    loading.value = true
    error.value = null

    try {
      if (!profile.value?.id) { throw new Error('No profile loaded') }

      const { data, error: supabaseError } = await supabase
        .from('profiles')
        .update(updates)
        .eq('id', profile.value.id)
        .select()
        .single()

      if (supabaseError) {
        useToast().toast({
          title: 'Error updating profile',
          variant: 'error',
        })
        throw supabaseError
      }

      profile.value = { ...profile.value, ...data } as UserProfile
      useToast().toast({
        title: 'Profile updated',
        variant: 'success',
      })
    }
    catch (e) {
      error.value = (e as Error).message
      useToast().toast({
        title: 'Error updating profile',
        description: error.value,
        variant: 'error',
      })
      console.error('Error updating user profile:', e)
    }
    finally {
      loading.value = false
    }
  }

  // Clear state
  function clearProfile(): void {
    profile.value = null
    error.value = null
    unsubscribeFromProfileChanges()
  }

  // Unsubscribe from real-time changes
  function unsubscribeFromProfileChanges(): void {
    if (profileSubscription.value) {
      profileSubscription.value.unsubscribe()
      profileSubscription.value = null
      useLogger().log('Unsubscribed from user profile real-time updates', '#realtime', '#unsubscribeFromProfileChanges')
    }
  }

  // Subscribe to real-time changes
  function subscribeToProfileChanges(userId: string): void {
    // Unsubscribe from any existing subscription
    unsubscribeFromProfileChanges()

    profileSubscription.value = supabase
      .channel(`public:profiles:id=eq.${userId}`)
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'profiles', filter: `id=eq.${userId}` },
        (payload) => {
          useLogger().log('Received real-time update:', '#realtime', '#subscribeToProfileChanges', payload)
          if (payload.eventType === 'UPDATE') {
            profile.value = { ...profile.value, ...(payload.new as UserProfile) }
            // useToast().toast({ title: 'Your profile was updated', variant: 'default', duration: 2000 })
          }
        },
      )
      .subscribe()
    useLogger().log('Subscribed to user profile for real-time updates', '#realtime', '#subscribeToProfileChanges')
  }

  // Watch loading state and trigger nuxt loading indicator
  watch(
    () => loading.value,
    (newValue) => {
      if (newValue) {
        startLoadingIndicator()
      }
      else {
        finishLoadingIndicator()
      }
    },
  )

  return {
    profile,
    loading,
    error,
    isLoggedIn,
    username,
    firstName,
    lastName,
    profileCompleted,
    onboardingCompleted,
    fetchProfile,
    updateProfile,
    clearProfile,
    unsubscribeFromProfileChanges,
  }
})

export type UserProfileStore = ReturnType<typeof useUserProfileStore>

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserProfileStore, import.meta.hot))
}
